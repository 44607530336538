import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import {
  AccountApi,
  TobAccountInfoOutputResponse,
  TobAccountSubListOutputResponse,
  TobAccountSubOrganizationRowDataResponse,
} from '../api-client';
import { useCurrentUser } from './useCurrentUser';
import { useParamClientAll } from './useParamClientAll';
import { useUrlParams } from './useUrlParams';

export interface NewRadioParam {
  defaultBelongCode: string;
  data: TobAccountSubListOutputResponse;
}

interface RadioParamContextInterface {
  mainAuth: string;
  setMainAuth: Dispatch<SetStateAction<string>>;
}
export interface Props {
  loginTobAccountId: number;
  newPageFlg: boolean;
  children: ReactNode;
}

// コンテキストを作成する
export const SharedRadioParamContext = createContext<RadioParamContextInterface>({
  mainAuth: '',
  setMainAuth: () => undefined,
});

export const SharedRadioParamProvider: React.FC<Props> = ({ loginTobAccountId, newPageFlg, children }) => {
  const params = useUrlParams();
  const strAccountId: string | undefined = params.accountUpdateId === '' ? undefined : `${params.accountUpdateId}`;
  const strAccountUpdateCOSId: string | undefined =
    params.accountUpdateClientOrgShopId === '' ? undefined : `${params.accountUpdateClientOrgShopId}`;
  // const accountInfo = useAccountInfo(strAccountId, strAccountUpdateCOSId);
  const api = new AccountApi();
  const [mainAuth, setMainAuth] = useState<string>('');
  const [accountInfo, setAccountInfo] = useState<TobAccountInfoOutputResponse>();
  const paramAllClient = useParamClientAll();
  const loginUser = useCurrentUser();
  useEffect(() => {
    // 新規登録画面
    if (newPageFlg) {
      // *** API ***//
      let data: TobAccountSubListOutputResponse;
      api.tobAccountRadioList(undefined, undefined, paramAllClient).then((res) => {
        data = res.data;
        if (data && mainAuth === '') {
          // if (radioSelected === null) {
          if (loginUser.belongCode && loginUser.belongCode !== '') {
            const split = loginUser.belongCode.split(',');
            let status = '1';
            //
            if (split[0] === 'C') {
              status = '1';
            } else if (split[0] === 'O') {
              data?.organization.map((org: TobAccountSubOrganizationRowDataResponse) => {
                if (org.organizationId === loginUser.belongCode) {
                  status = org.hierarchyId!;
                }
              });
            } else if (split[0] === 'S') {
              status = '3';
            }
            setMainAuth(status!);
          }
        }
      });
    } else {
      // *** API ***//
      // api.tobAccountInfo(strAccountId, strAccountUpdateCOSId, paramAllClient).then((res) => {
      api.tobAccountInfo(undefined, undefined, paramAllClient, 'true').then((res) => {
        setAccountInfo(res.data);
      });

      let data: TobAccountSubListOutputResponse;
      api.tobAccountRadioList(undefined, undefined, paramAllClient).then((res) => {
        data = res.data;
        if (data && mainAuth === '') {
          // if (radioSelected === null) {
          if (accountInfo?.account?.belongCode && accountInfo?.account?.belongCode !== '') {
            const split = accountInfo?.account?.belongCode.split(',');
            let status = '1';
            //
            if (split[0] === 'C') {
              status = '1';
            } else if (split[0] === 'O') {
              data?.organization.map((org: TobAccountSubOrganizationRowDataResponse) => {
                if (org.organizationId === accountInfo?.account?.belongCode) {
                  status = org.hierarchyId!;
                }
              });
            } else if (split[0] === 'S') {
              status = '3';
            }
            setMainAuth(status!);
          }
        }
      });
    }
  }, []);
  const value: RadioParamContextInterface = {
    mainAuth,
    setMainAuth,
  };
  return <SharedRadioParamContext.Provider value={value}>{children}</SharedRadioParamContext.Provider>;
};
